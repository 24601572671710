import React, {useEffect} from "react";
import AuthView from '../components/AuthView';
import SecurityProvider from "../components/security-provider";
import {closeAuthView, openAuthView} from "../store/app/actions";
import { useDispatch } from 'react-redux';

const SignupPopupEmbedPage = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
             // this code will run only when user leaves the AuthView
             dispatch(closeAuthView(false));
        };
    }, []);  // Empty dependency array ensures this runs only on unmount


    const businessAppConfiguration =
      props.pageContext.businessAppConfiguration || {};
    const { useRecaptchaInSignUp } = businessAppConfiguration;
    const { recaptchaKey } = businessAppConfiguration;
    const loadWithSecurityProvider = recaptchaKey && useRecaptchaInSignUp;
    return loadWithSecurityProvider ? (
      <SecurityProvider
        useReacptcha={useRecaptchaInSignUp}
        recaptchaKey={recaptchaKey}
      >
        <AuthView {...props} isSignupPopupMode={true} />
      </SecurityProvider>
    ) : (
        <AuthView {...props} isSignupPopupMode={true} />
    );
  };

export default SignupPopupEmbedPage;
